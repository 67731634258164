@import '../style/colors';

.button {
	background-color: $theme-accent;
	color: white;
	border: none;
	font-family: inherit;
	padding: 0.6rem 1rem;
	border-radius: 8px;
	text-transform: uppercase;
	font-weight: 600;
	cursor: pointer;
	transition: all .3s;
	text-decoration: none;
	display: inline-flex;

	&:hover {
		background-color: lighten($theme-accent, 5%);
	}

	&:active, &:focus {
		box-shadow: 0 0 0 4px rgba($theme-accent, 0.3);
	}

	&--text {
		background-color: unset;
		color: $theme-accent;

		&:hover {
			background-color: rgba($theme-accent, 0.1);
		}
	}
}