@import "../style/colors";

.favorite {
	color: $theme-primary;
	text-align: center;

	&__image {
		width: 6em;
		height: 6em;
		object-fit: contain;
		margin-bottom: 0.5rem;
	}

	&__title {
		font-weight: 600;
	}

	&__subtitle {
		font-weight: 300;
	}

}