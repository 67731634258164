@import "../style/colors";

.skill {

	&__details {
		display: flex;
		align-items: center;
		font-weight: 600;
		color: $theme-primary;
	}

	&__subtitle {
		color: gray;
		font-weight: 400;
	}

	&__bar {
		height: 0.6rem;
		background-color: #E6E6E6;
		margin-top: 0.25rem;
	}

	&__bar-inner {
		width: 0;
		height: 100%;
		transition: width 1s;
		background-color: $theme-accent;
	}

}