@import '../style/colors';

.contact {
	padding-bottom: 8rem;

	ul {
		list-style: none;
		color: $theme-accent;
		padding: 0;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	li {
		display: flex;
		align-items: center;
		margin-bottom: 0.75rem;

		span {
			color: #979797;
		}
	}

	svg {
		margin-right: 8px;
	}

}