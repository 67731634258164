@import '../style/colors';

.title {

	h1 {
		font-weight: 800;
		font-size: 3.5rem;
		margin: 0;
		margin: 0;
	}

	h2 {
		color: gray;
		font-weight: 600;
		font-size: 1.3rem;
		margin-top: 0.25rem;
	}

	&__line {
		margin-top: 1.75rem;
		height: 0.5rem;
		width: 10rem;
		background-color: $theme-accent;
		border-radius: 100px;
		margin-bottom: 2.5rem;
	}

}