@import "../style/responsive";

.projects {

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include breakpoint(tablet) {
			flex-direction: row;
		}
	}

	&__divider {
		border-bottom: 1px solid #e6e6e6;

		@include breakpoint(tablet) {
			border-right: 1px solid #e6e6e6;
		}
	}

	&__list {
		margin-top: 3.5rem;
	}

	.project {
		flex: 1;
		margin-top: 2rem;

		&:first-child {
			margin-top: 0;
		}
	}

	.button {
		margin-bottom: 2rem;
	}

}