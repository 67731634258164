.project {
	text-align: center;
	padding-inline: 2.5rem;

	h1 {
		font-size: 1.4rem;
		font-weight: 600;
		margin-bottom: 0;
	}

	h2 {
		margin-top: 0;
		font-weight: 300;
		color: gray;
	}

	p {
		text-align: left;
		margin-bottom: 2.5rem;
	}
}