$bp-mobile: 650px;
$bp-tablet: 850px;
$bp-desktop: 1250px;

$breakpoints: (
	mobile: $bp-mobile,
	tablet: $bp-tablet,
	desktop: $bp-desktop
);

@mixin breakpoint($size) {
	@media (min-width: map-get($breakpoints, $size)) {
		@content;
	}
}