@import '../style/responsive';

.about-me {

	> .container {
		display: grid;

		@include breakpoint(tablet) {
			grid-template-columns: 3fr 2fr;
		}
	}

	.title__line {
		margin-bottom: 1rem;
	}

	&__timeline {
		float: right;
		display: none;

		@include breakpoint(tablet) {
			display: block;
		}
	}

}