@import '../style/responsive';

.landing {

	> .container {
		display: grid;
		grid-auto-flow: column;

		@include breakpoint(tablet) {
			grid-auto-columns: minmax(0, 1fr);
		}
	}

	&__profile {
		float: right;
		display: none;

		@include breakpoint(tablet) {
			display: block;
		}
	}

	&__actions {
		display: flex;
		gap: 0.5rem;
	}

}