@import '../style/colors';
@import '../style/responsive';

.toolbar {
	transition: background-color .5s, backdrop-filter .5s;
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	
	&--floating {
		background-color: rgba(white, 0.75);
		backdrop-filter: blur(10px);
	}
	
	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 1rem;
		min-height: 3rem;
		gap: 0.25rem;

		@include breakpoint(tablet) {
			padding-top: 0;
			flex-direction: row;
		}
	}

	img {
		height: 2rem;
	}

	ol {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;
		font-size: 0.65rem;

		@include breakpoint(tablet) {
			font-size: 1rem;
		}
	}

	li {
		transition: color .3s;
		text-transform: uppercase;
		padding-inline: 1rem;
		color: $theme-primary;
		position: relative;
		font-weight: bold;
		cursor: pointer;
		height: 3rem;
		display: flex;
		align-items: center;
		text-align: center;

		&::after {
			content: '';
			width: 10px;
			height: 10px;
			border-radius: 100px;
			transition: transform .3s, width .3s;
			background-color: $theme-primary;
			position: absolute;
			margin-inline: auto;
			left: 0;
			right: 0;
			bottom: 0;
			transform: scale(0);
			transform-origin: center;
		}

		&:hover::after {
			transform: scale(0.8);
		}

		&.active {
			color: $theme-accent;

			&::after {
				background-color: $theme-accent;
				transform: scale(1);
				width: 10px;
			}
		}
	}
}