@import "../style/responsive";

.skills {

	&__list {
		margin-top: 2.5rem;
		gap: 4rem;
		padding-bottom: 2.5rem;
		margin-bottom: 2.5rem;
		border-bottom: 1px solid #e6e6e6;

		@include breakpoint(tablet) {
			column-count: 2;
		}
	}

	&__fav-subtitle {
		color: gray;
		font-weight: 400;
		font-size: 1.2rem;
		font-weight: 600;
		text-align: center;
	}

	&__fav-title {
		color: black;
		text-align: center;
		font-weight: 800;
		font-size: 2rem;
	}

	&__favorites {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 2.5rem;
		gap: 5rem;

		@include breakpoint(tablet) {
			flex-direction: row;
		}
	}

	.skill {
		margin-bottom: 1.2rem;
	}

}