@import "https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap";

@import './colors';
@import './responsive';
@import './typography';

html,
body {
	margin: 0;
	padding: 0;
	font-family: Rubik, ui-sans-serif, system-ui;
	scroll-snap-type: y proximity;
}

h1, h2, h3, h4, h5, h6 {
	font-size: unset;
	font-weight: unset;
}

p {
	color: $theme-primary;
	font-size: 1.2rem;
	font-weight: 300;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

.site {
	position: relative;
}

.container {
	width: 100%;
	margin-inline: auto;
	padding-inline: 1.25rem;

	@each $breakpoint, $value in $breakpoints {
		@include breakpoint($breakpoint) {
			max-width: $value;
		}
	}
}

.segment {
	scroll-snap-align: start;
	position: relative;
	min-height: 100vh;
	padding-top: 10rem;

	// &:nth-child(even) {
	// 	background-color: rgb(252, 231, 231);
	// }
}

.blob {
	pointer-events: none;
	position: absolute;
	z-index: -1;

	&--1 {
		left: 0;
		top: 15vh;
		width: 7vw;
	}

	&--2 {
		top: 0;
		right: 0;
		width: 36vw;
	}

	&--3 {
		top: 10vh;
		left: 0;
		width: 4vw;
	}

	&--4 {
		bottom: -18vh;
		right: 0;
		width: 10vw;
	}

	&--5 {
		bottom: 6vh;
		left: 0;
		width: 8vw;
	}

	&--6 {
		bottom: -16vh;
		right: 0;
		width: 8.5vw;
	}

	&--7 {
		bottom: 0;
		left: 0;
		width: 26vw;
	}
}

.footer {
	position: absolute;
	bottom: 0.5rem;
	left: 0;
	right: 0;
	display: flex;
	color: #a1a8c0;
}